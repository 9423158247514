import React, { useEffect, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";

//import redux
import store from "@/redux/store";
import { useDispatch } from "react-redux";

const ImageUI = dynamic(() => import("@/common/UI/Image"));
const MetaTags = dynamic(() => import("@/common/MetaTags"));

import {
  fetchLabelsAndConfigData,
  setLabelsConfigsData,
} from "@/redux/actions/labelsAndConfigActions";

// Import graphQL query variable
import { LABEL_AND_CONFIG_QUERY } from "@/graphql/settingsQuery";

export default function Custom404({ labelsConfigData }) {
  const [siteConfig, setSiteConfig] = useState({});
  const [labelsList, setLabelsList] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLabelsConfigsData(labelsConfigData));
    setSiteConfig(labelsConfigData?.siteConfig);
    setLabelsList(labelsConfigData?.labelsList);
  }, [dispatch, labelsConfigData]);

  return (
    <>
      <MetaTags />
      <section className="min-h-[calc(100vh-132px)] flex items-center justify-center pt-28 md:pt-10 pb-28 md:pb-10 md:min-h-50 lg:min-h-50 xl:min-h-75 relative">
        <div className="z-0 back">
          {siteConfig?.errorBackground?.url && (
            <ImageUI
              src={siteConfig?.errorBackground?.url}
              alt={siteConfig?.errorBackground?.alt || "bg"}
              className="h-full object-cover object-center"
              layout="fill"
              priority={true}
              loading="eager"
              unoptimized={true}
            />
          )}
        </div>
        <div className="max-w-4xl p-5 mx-auto text-center z-[1] text-white w-full">
          <h1 className="text-[80px] font-bold tracking-widest text-center pb-2">
            {siteConfig?.missingPageTitle || "404"}
          </h1>
          <p className="text-[28px] block font-light">
            {siteConfig?.missingPageDescription || "error"}
          </p>
          <div className="text-center">
            <Link
              href="/"
              className="inline-block mt-12 uppercase font-bold border-2 border-white py-4 px-10  text-white hover:bg-success hover:border-success transition-all tracking-[3px] hover:text-white"
            >
              {labelsList?.backHome || "Go Home"}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export async function getStaticProps({ preview = false, locale = "en" }) {
  const labelsConfigData = await store.dispatch(
    fetchLabelsAndConfigData(LABEL_AND_CONFIG_QUERY, preview, { lang: locale })
  );

  return {
    props: {
      preview,
      labelsConfigData: labelsConfigData || {},
    },
  };
}
